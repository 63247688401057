import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import EditUser from '../Users/EditUser';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");

class ListUsers extends React.Component {
    constructor(props) {
        super(props);

        this.loadUsers = this.loadUsers.bind(this);

        this.state = {
            loading: true,
            // default search settings
            product_id: null,
            // default search filter names
            product_name: "All",
            action_types: {0: "Write-Off (Keep Deposit)", 1: "Pickup (Return Deposit)"},
            users: [],
            accounts: [],
        };
    }

    componentDidMount() {
        document.title = "Users | Symbev";
        var self = this;
        self.loadUsers();
    }

    loadUsers() {
        this.setState({loading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/users/')
            .then(function (response) {
                self.setState(
                    {
                        users: response.data,
                        loading: false,
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    changeStatus = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name
        }, () => {
            this.updateUsers();
        });
    };


    render() {

        return (
            <ContentWrapper>
                <div className="content-heading">
                    Users
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">{this.state.users.length} Users</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.users.length > 0 && !this.state.loading ? "table table-hover" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Order Reminders</th>
                                        <th>Account(s)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.users.map(function (user, key) {
                                        return (
                                            <tr key={key}>
                                                <td><EditUser first_name={user.first_name} last_name={user.last_name} user_id={user.user_id} loadUsers={this.loadUsers}/></td>
                                                <td>{user.email}</td>
                                                <td><span
                                                    className={(user.status == 1 ? "badge badge-success" : "badge badge-danger")}>{(user.status == 1 ? "Active" : "Closed")}</span>
                                                </td>
                                                <td><span
                                                    className={(user.reminder == 1 ? "badge badge-success" : "badge badge-danger")}>{(user.reminder == 1 ? "On" : "Disabled")}</span>
                                                </td>
                                                <td>
                                                    {user.accounts.map(function (account, key2) {
                                                        return (
                                                            <div key={key2}><Link to={"../account/" + account.account_id}>{account.name}</Link></div>
                                                        )
                                                    }, this)}
                                                </td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (ListUsers);
