import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getProducts} from '../Products/ProductFunctions.js';
import {getStaff} from '../Staff/StaffFunctions.js';
import {getAccounts} from '../Accounts/AccountFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getOwners} from "../Owners/OwnerFunctions";

moment.tz.setDefault("America/Toronto");

class InventoryTransactions extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            transactions: [],
            loading: true,
            // default search settings
            action_type: null,
            category: null,
            inventory_source_id: null,
            product_id: null,
            staff_id: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            // default search filter names
            category_name: "All",
            inventory_source_print: "All",
            product_name: "All",
            staff_name: "All",
            // data
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : 99),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "Retail & OP"),
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
            account_types: {
                0: "Special",
                1: "On-Premise - Cat A",
                9: "On-Premise - Cat B",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                10: "Convenience",
                97: "All OP",
                98: "All Retail",
                99: "All OP & Retail"
            },
            codes: {1: "New Product", 4: "Reconciliation", 2: "Damaged", 3: "Sample", 0: "Transfer"},
            categories: {
                in: "In (all)",
                in_1: "In, New Product",
                in_4: "In, Reconciliation",
                out: "Out (all)",
                out_2: "Out, Damaged",
                out_3: "Out, Samples",
                out_4: "Out, Reconciliation",
                out_99: "Out, Purchase",
                0: "Transfers"
            },
            action_types: {0: "Transfer", 1: "Addition", 2: "Removal"},
            inventory_sources: [],
            products: [],
            staff: [],
            accounts: [],
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            }
        };
    }

    componentDidMount() {
        document.title = "Inventory Transactions | Symbev";
        var self = this;
        getInventorySources(null, function (inventory_sources) {
            getProducts(function (products) {
                getStaff(function (staff) {
                    getAccounts(function (accounts) {
                        getOwners(function (owners) {
                            self.setState({
                                inventory_sources: inventory_sources,
                                products: products,
                                staff: staff,
                                accounts: accounts,
                                companies: owners
                            });
                            self.getTransactions();
                        });
                    });
                });
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getTransactions()
            });
        }
    }

    changeCategory = (category) => {
        if (category == null) {
            var category_name = "All"
        } else {
            var category_name = this.state.categories[category]
        }
        this.setState({
            category: category,
            category_name: category_name
        }, () => {
            this.getTransactions()
        });
    };
    changeProduct = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name
        }, () => {
            this.getTransactions()
        });
    };
    changeInventory = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var inventory_source_print = "All"
        } else {
            var inventory_source_print = this.state.inventory_sources[inventory_source_id].name
        }
        this.setState({
            inventory_source_id: inventory_source_id,
            inventory_source_print: inventory_source_print
        }, () => {
            this.getTransactions()
        });
    };
    changeStaff = (staff_id) => {
        if (staff_id == null) {
            var staff_name = "All"
        } else {
            var staff_name = this.state.staff[staff_id].first_name + " " + this.state.staff[staff_id].last_name.charAt(0) + ".";
        }
        this.setState({
            staff_id: staff_id,
            staff_name: staff_name
        }, () => {
            this.getTransactions()
        });
    };
    changeAccountType = (account_type) => {
        if (account_type == null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type', account_type);
        localStorage.setItem('account_type_name', account_type_name);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        }, () => {
            this.getTransactions()
        });
    }
    changeCompany = (company_id) => {
        if (company_id == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company_id].name
        }
        localStorage.setItem('company', company_id);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company_id,
            companies_name: companies_name
        }, () => {
            this.getTransactions()
        });
    }

    refreshSearch = event => {
        this.getTransactions();
    };

    getTransactions() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/inventory/transactions/?action_type=' + self.state.action_type + "&category=" + self.state.category + "&inventory_source_id=" + self.state.inventory_source_id + "&product_id=" + self.state.product_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=" + self.state.order_type + "&staff_id=" + self.state.staff_id + "&account_type=" + self.state.account_type + "&company_id=" + self.state.company)
            .then(function (response) {
                self.setState({
                    transactions: response.data,
                    loading: false

                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var total_quantity = parseFloat(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../inventory">Inventory</Link>&nbsp;/&nbsp;Transactions
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Companies: <b>{this.state.companies_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.companies).map((key, i) => {
                                        if (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1) {
                                            return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Category: <b>{this.state.category_name}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeCategory(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.categories).map((key) =>
                                <DropdownItem key={key} onClick={() => this.changeCategory(key)}>{this.state.categories[key]}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Product: {this.state.product_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.products).map((products_id) =>
                                <DropdownItem key={products_id} onClick={() => this.changeProduct(products_id)}>{this.state.products[products_id].name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color="primary" caret style={{width: 'auto'}}>
                            Source: {this.state.inventory_source_print}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeInventory(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                return (
                                    <DropdownItem key={inventory_source_id}
                                                  onClick={() => this.changeInventory(inventory_source_id, 1)}>{(inventory_source_id == 0 ? "--" : this.state.inventory_sources[inventory_source_id].name)}</DropdownItem>
                                )
                            }, this)}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color="primary" caret style={{width: 'auto'}}>
                            By: {this.state.staff_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeStaff(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.staff).map(function (staff_id, key) {
                                return (
                                    <DropdownItem key={staff_id}
                                                  onClick={() => this.changeStaff(staff_id, 1)}>{this.state.staff[staff_id].first_name} {this.state.staff[staff_id].last_name}</DropdownItem>
                                )
                            }, this)}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color={(String(this.state.account_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                            Account Type: <b>{this.state.account_type_name}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.account_types).map((key, i) =>
                                <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">{this.state.transactions.length} Matching Transactions</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.transactions.length > 0 && !this.state.loading ? "table table-hover" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-xl-table-cell">ID</th>
                                        <th>Date</th>
                                        <th>Product</th>
                                        <th>Inventory Source</th>
                                        <th className="text-right">Qty.</th>
                                        <th className="d-none d-sm-table-cell">Type</th>
                                        <th className="d-none d-sm-table-cell">Notes</th>
                                        <th className="d-none d-xl-table-cell">Date Entered</th>
                                        <th className="d-none d-sm-table-cell">By</th>
                                        <th className="d-none d-sm-table-cell">License</th>
                                        <th className="d-none d-sm-table-cell">Address</th>
                                        <th className="d-none d-sm-table-cell">Rep</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transactions.map(function (item, key) {
                                        var id_prefix = "ORD-";
                                        var quantity = item.quant;
                                        var notes = "";
                                        var order_text = "";
                                        var order_id = 0;
                                        var url = "";
                                        var category = "Purchase";
                                        if (quantity > 0) {
                                            quantity = "+" + quantity;
                                        }
                                        total_quantity = (parseFloat(quantity) + total_quantity);
                                        var d_ent = item.d_ent;
                                        if (typeof d_ent == "undefined") {
                                            d_ent = item.d_eff;
                                        }
                                        if (typeof item.order == "undefined" && typeof item.bol == "undefined") {
                                            id_prefix = "ADJ-";
                                            category = this.state.codes[item.code];
                                            notes = item.notes;
                                            var by = "-";
                                            if (typeof this.state.staff[item.staff] != "undefined") {
                                                by = this.state.staff[item.staff].first_name + " " + this.state.staff[item.staff].last_name.charAt(0) + ".";
                                            }
                                        } else {
                                            if (typeof item.bol == "undefined") {
                                                order_text = "Order " + item.order;
                                                var url = '/order/' + item.order
                                            } else {
                                                id_prefix = "BOL-";
                                                order_text = "BOL " + item.bol;
                                                var url = '/bol/' + item.bol
                                            }
                                            if (typeof this.state.accounts[item.acct] == "undefined") {
                                                var by = "";
                                                var license = "";
                                                var self = this;
                                                getAccounts(function (accounts) {
                                                    self.setState({
                                                        accounts: accounts
                                                    });
                                                    self.getTransactions();
                                                });
                                            } else {
                                                var by = this.state.accounts[item.acct].name;
                                                var license = this.state.accounts[item.acct].license_num;
                                                var address1 = this.state.accounts[item.acct].address1;
                                                var rep_id = this.state.accounts[item.acct].sales_rep_id;
                                                var sales_rep = "Unassigned";
                                                if (rep_id != null && typeof this.state.staff[rep_id] != "undefined") {
                                                    sales_rep = this.state.staff[rep_id].first_name + " " + this.state.staff[rep_id].last_name.charAt(0);
                                                }
                                            }
                                        }

                                        return (
                                            <tr key={key}>
                                                <td className="d-none d-xl-table-cell">{id_prefix}{item.id}</td>
                                                <td>{item.d_eff}</td>
                                                <td>{(typeof this.state.products[item.prod] == "undefined" ? "?" : this.state.products[item.prod].name)}</td>
                                                <td>{(item.src == 0 ? "--" : (typeof this.state.inventory_sources[item.src] != "undefined" ? this.state.inventory_sources[item.src].name : "?"))}</td>
                                                <td className={(quantity > 0 ? "text-success text-right" : "text-right text-danger")}>{quantity}</td>
                                                <td>{category}</td>
                                                <td>{notes}<Link to={url}>{order_text}</Link></td>
                                                <td className="d-none d-xl-table-cell">{item.d_ent}</td>
                                                <td className="d-none d-sm-table-cell">{by}</td>
                                                <td className="d-none d-sm-table-cell">{license}</td>
                                                <td className="d-none d-sm-table-cell">{address1}</td>
                                                <td className="d-none d-sm-table-cell">{sales_rep}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colspan="4" className="text-right">Total:</th>
                                        <th>{total_quantity}</th>
                                        <th colspan="7"></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (InventoryTransactions);
